import React from 'react';
// Hook
// https://gist.github.com/mbelsky/72c1117a63489daf8e6067049d4532d0
export var useHover = function () {
    var _a = React.useState(false), value = _a[0], setValue = _a[1];
    // Wrap in useCallback so we can use in dependencies below
    var handleMouseOver = React.useCallback(function () { return setValue(true); }, []);
    var handleMouseOut = React.useCallback(function () { return setValue(false); }, []);
    // Keep track of the last node passed to callbackRef
    // so we can remove its event listeners.
    var ref = React.useRef();
    // Use a callback ref instead of useEffect so that event listeners
    // get changed in the case that the returned ref gets added to
    // a different element later. With useEffect, changes to ref.current
    // wouldn't cause a rerender and thus the effect would run again.
    var callbackRef = React.useCallback(function (node) {
        if (ref.current) {
            ref.current.removeEventListener('mouseover', handleMouseOver);
            ref.current.removeEventListener('mouseout', handleMouseOut);
        }
        ref.current = node || undefined;
        if (ref.current) {
            ref.current.addEventListener('mouseover', handleMouseOver);
            ref.current.addEventListener('mouseout', handleMouseOut);
        }
    }, [handleMouseOver, handleMouseOut]);
    return [callbackRef, value];
};
