export var IMAGES = {
    arraow_left: require('./icon-28-x-28-line-arrow-left.svg'),
    arraow_right: require('./icon-28-x-28-line-arrow-right.svg'),
    arraow_up: require('./icon-20-x-20-line-arrow-up.svg'),
    line_calendar: require('./icon-28-x-28-line-calendar.svg'),
    fill_message: require('./icon-20-x-20-fill-message.svg'),
    fill_time: require('./icon-20-x-20-fill-time.svg'),
    announcement: require('./icon-24-x-24-fill-announcement.svg'),
    notification: require('./icon-24-x-24-fill-notification.svg'),
    notification_gray: require('./icon-24-x-24-notification-gray.svg'),
    helpcenter: require('./d-question-circle-fill.svg'),
    staff: require('./icon-20-x-20-fill-staff.svg'),
    shop: require('./icon-20-x-20-fill-shop.svg'),
    logo: require('./logo.svg'),
    more: require('./icon-24-x-24-fill-more.svg'),
    check: require('./icon-20-x-20-check.svg'),
    exclamation: require('./icon-20-x-20-exclamation.svg'),
    question: require('./icon-20-x-20-question.svg'),
    won: require('./icon-20-x-20-won.svg'),
    check_white: require('./icon-20-x-20-check-white.svg'),
    exclamation_white: require('./icon-20-x-20-exclamation-white.svg'),
    question_white: require('./icon-20-x-20-question-white.svg'),
    won_white: require('./icon-20-x-20-white-won.svg'),
    line_ticket: require('./icon-20-x-20-line-ticket.svg'),
    fill_error: require('./icon-24-x-24-fill-error.svg'),
    fill_success: require('./icon-24-x-24-fill-success.svg'),
    icon_drag: require('./icon-drag-normal.svg'),
    icon_drag_hover: require('./icon-drag-hover.svg'),
    profile_placeholder: require('./profile-placeholder.svg'),
    qr_send_icon: require('./icon-16-x-16-qr-g-1.svg'),
    quick_icon: require('./icon-24-x-24-quick.svg'),
    calendar_week: require('./icon-calendar-20-x-20-week.svg'),
    calendar_7days: require('./icon-calendar-20-x-20-7-days.svg'),
    calendar_table: require('./icon-calendar-20-x-20-table.svg'),
    icon_variable: require('./icon-variable.svg'),
    kakao_icon: require('./icon-kakaotalk.svg'),
    empty_cart: require('./icon-emptycart.svg'),
    plan_inst1: require('./plan-inst1.png'),
    plan_inst2: require('./plan-inst2.png'),
    plan_inst3: require('./plan-inst3.png'),
    icon_help: require('./help-web-button-24-px.svg'),
    recommend_friend: require('./recommend-friend.svg'),
    review_connector_beta: require('./review-connector-beta.png'),
    update_popup_image: require('./update-popup-image.png'),
    schedule_icon_naver: require('./icon-14-x-14-naver.svg'),
    schedule_icon_naver_error: require('./icon-14-x-14-naver-error.svg'),
    schedule_icon_thinkofyou: require('./icon-14-x-14-thinkofyou.svg'),
    schedule_icon_deposit_pending: require('./icon-14-x-14-deposit-pending.svg'),
    schedule_icon_deposit_complete: require('./icon-14-x-14-deposit-complete.svg'),
    schedule_icon_deposit_under: require('./icon-14-x-14-deposit-under.svg'),
    schedule_icon_deposit_over: require('./icon-14-x-14-deposit-over.svg'),
    schedule_icon_sales: require('./icon-20-x-20-sales.svg'),
    app_icon: require('./app-icon.svg'),
    icon_star: require('./icon-star.svg'),
    icon_quick_pay: require('./icon-16-x-16-quickpay.svg'),
    icon_setting_error: require('./icon-settingerror-28-x-28.svg'),
    input_text: require('./input-text.svg'),
    input_textarea: require('./input-textarea.svg'),
    input_select: require('./input-select.svg'),
    input_datepicker: require('./input-datepicker.svg'),
    icon_refund: require('./icon-d-20-refund.svg'),
    blog_marketing_1: require('./blogmarketing-image-1.png'),
    blog_marketing_2: require('./blogmarketing-image-2.png'),
    blog_marketing_3: require('./blogmarketing-image-3.png'),
    blog_marketing_4: require('./blogmarketing-image-4.png'),
    blog_marketing_5: require('./blogmarketing-image-5.png'),
    blog_marketing_6: require('./blogmarketing-image-6.png'),
    blog_marketing_7: require('./blogmarketing-image-7.png'),
    blog_marketing_8: require('./blogmarketing-image-8.png'),
    marketing_icon: require('./icon-d-24-marketing.svg'),
    landing_banner_in_signup_bg: require('./landing-banner-in-signup-bg.png'),
    landing_banner_in_signup: require('./landing-banner-in-signup.png'),
    additional_image_1: require('./additional-image-1.svg'),
    additional_image_2: require('./additional-image-2.svg'),
    additional_image_3: require('./additional-image-3.svg'),
    additional_image_4: require('./additional-image-4.png'),
    additional_image_5: require('./additional-image-5.svg'),
    additional_image_6: require('./additional-image-6.svg'),
    additional_image_7: require('./additional-image-7.png'),
    additional_image_8: require('./additional-image-8.png'),
    additional_image_9: require('./additional-image-9.png'),
    additional_image_10: require('./additional-image-10.png'),
    additional_image_11: require('./additional-image-11.png'),
    remote_class_image: require('./remote-class-inst.png'),
    customer_relation_empty_button: require('./customer-relation-empty-button.svg'),
    transfer_setting_empty_button: require('./transfer-setting-empty-button.svg'),
    naver_booking_example: require('./naver-example.png'),
    icon_link_out: require('./icon-20-link-out.svg'),
    icon_warning: require('./d-64-warning.svg'),
    free_trial_banner_image: require('./free-trial-banner-image.svg'),
    kakao_webview_guide: require('./kakaowebview-guide.png'),
    daily_promotion_image: require('./daily_promotion_image.png')
};
