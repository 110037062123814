var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { TextComponent, CustomDropdownMenu, formmattedMessage, UnderlineClickableText } from 'renderer/components';
import styled from 'styled-components';
import { COLORS, MediaQuery } from 'renderer/styles';
import { Row } from 'antd/lib/grid';
import { IMAGES } from 'renderer/assets';
import { StoreQuery } from 'renderer/queries';
import Dropdown from 'antd/lib/dropdown';
import { openWindow } from 'renderer/utils';
import { useWindowSize } from 'renderer/utils';
import { Atoms } from 'renderer/stores';
import { useRecoilState, useRecoilValue } from 'recoil';
import { HEADER_HEIGHT } from 'renderer/constants';
export var GUIDE_NAMES;
(function (GUIDE_NAMES) {
    GUIDE_NAMES["\uC608\uC57D\uCE98\uB9B0\uB354"] = "\uC608\uC57D\uCE98\uB9B0\uB354";
    GUIDE_NAMES["\uACE0\uAC1D\uB9AC\uC2A4\uD2B8"] = "\uACE0\uAC1D\uB9AC\uC2A4\uD2B8";
    GUIDE_NAMES["\uC120\uBD88\uACE0\uAC1D/\uD3EC\uC778\uD2B8\uAD00\uB9AC"] = "\uC120\uBD88\uACE0\uAC1D/\uD3EC\uC778\uD2B8 \uAD00\uB9AC";
    GUIDE_NAMES["\uB9E4\uCD9C\uB4F1\uB85D/\uBAA9\uB85D"] = "\uB9E4\uCD9C \uB4F1\uB85D/\uBAA9\uB85D";
    GUIDE_NAMES["\uC0C1\uC810\uB9E4\uCD9C\uBD84\uC11D"] = "\uC0C1\uC810\uB9E4\uCD9C \uBD84\uC11D";
    GUIDE_NAMES["\uC9C1\uC6D0\uBCC4\uACB0\uC0B0"] = "\uC9C1\uC6D0\uBCC4 \uACB0\uC0B0";
    GUIDE_NAMES["\uC774\uC6A9\uB960\uBD84\uC11D"] = "\uC774\uC6A9\uB960 \uBD84\uC11D";
    GUIDE_NAMES["\uC0C1\uD488\uAD00\uB9AC"] = "\uC0C1\uD488\uAD00\uB9AC";
    GUIDE_NAMES["\uC120\uBD88\uC561\u2219\uC815\uAE30\uAD8C \uAD00\uB9AC"] = "\uC120\uBD88\uC561\u2219\uC815\uAE30\uAD8C \uAD00\uB9AC";
    GUIDE_NAMES["\uBB38\uC790\uBC1C\uC2E0\uC815\uBCF4\uC124\uC815"] = "\uBB38\uC790 \uBC1C\uC2E0\uC815\uBCF4 \uC124\uC815";
    GUIDE_NAMES["\uBB38\uC790\uC790\uB3D9\uBC1C\uC1A1\uC124\uC815"] = "\uBB38\uC790 \uC790\uB3D9\uBC1C\uC1A1 \uC124\uC815";
    GUIDE_NAMES["\uBB38\uC790\uB0B4\uC5ED"] = "\uBB38\uC790 \uB0B4\uC5ED";
    GUIDE_NAMES["\uB0B4\uBB38\uC790\uBCF4\uAD00\uD568"] = "\uB0B4 \uBB38\uC790 \uBCF4\uAD00\uD568";
    GUIDE_NAMES["\uC0C1\uC810\uC815\uBCF4\uAD00\uB9AC"] = "\uC0C1\uC810 \uC815\uBCF4 \uAD00\uB9AC";
    GUIDE_NAMES["\uC11C\uBE44\uC2A4\uC694\uAE08\uC81C\uAD00\uB9AC"] = "\uC11C\uBE44\uC2A4 \uC694\uAE08\uC81C \uAD00\uB9AC";
    GUIDE_NAMES["\uBB38\uC790\uD3EC\uC778\uD2B8\uAD00\uB9AC"] = "\uBB38\uC790 \uD3EC\uC778\uD2B8 \uAD00\uB9AC";
    GUIDE_NAMES["\uC9C1\uC6D0\uAD00\uB9AC"] = "\uC9C1\uC6D0 \uAD00\uB9AC";
    GUIDE_NAMES["\uC608\uC57D\uCE98\uB9B0\uB354\uC124\uC815"] = "\uC608\uC57D\uCE98\uB9B0\uB354 \uC124\uC815";
    GUIDE_NAMES["\uACE0\uAC1D\uB4F1\uAE09\uC124\uC815"] = "\uACE0\uAC1D \uB4F1\uAE09 \uC124\uC815";
    GUIDE_NAMES["\uACE0\uAC1D\uD0DC\uADF8\uC124\uC815"] = "\uACE0\uAC1D \uD0DC\uADF8 \uC124\uC815";
    GUIDE_NAMES["\uAE30\uD0C0 \uACB0\uC81C\uC218\uB2E8 \uC124\uC815"] = "\uAE30\uD0C0 \uACB0\uC81C\uC218\uB2E8 \uC124\uC815";
    GUIDE_NAMES["\uD305\uCEE4\uBDF0\uB9C1\uD06C"] = "\uD305\uCEE4\uBDF0\uB9C1\uD06C";
    GUIDE_NAMES["\uC608\uC57D\uAE08 \uC785\uAE08\uB0B4\uC5ED"] = "\uC608\uC57D\uAE08 \uC785\uAE08\uB0B4\uC5ED";
    GUIDE_NAMES["\uC7AC\uACE0\uAD00\uB9AC"] = "\uC7AC\uACE0\uAD00\uB9AC";
    GUIDE_NAMES["\uC218\uC785\uC9C0\uCD9C\uAD00\uB9AC"] = "\uC218\uC785\uC9C0\uCD9C\uAD00\uB9AC";
    GUIDE_NAMES["\uACC4\uC815\uC124\uC815"] = "\uACC4\uC815\uC124\uC815";
})(GUIDE_NAMES || (GUIDE_NAMES = {}));
export var SUBHEADER_HEIGHT = 0;
export var HEADER_WITH_SUBHEADER = HEADER_HEIGHT + SUBHEADER_HEIGHT;
// export const TABLE_HEIGHT =
export function BodyTemplate(props) {
    var content = props.content;
    var title = props.title || formmattedMessage({ id: props.titleId });
    var guideName = props.guideName || Object.values(GUIDE_NAMES).find(function (item) { return item === title; });
    var isPC = useWindowSize().isPC;
    var _a = useRecoilState(Atoms.globalHeaderState), _ = _a[0], updateGlobalHeader = _a[1];
    var headerHeight = useRecoilValue(Atoms.globalHeaderHeightState) || 60;
    React.useEffect(function () {
        if (typeof title === 'string') {
            updateGlobalHeader({ title: title, guideName: guideName });
        }
    }, [title, guideName]);
    return (React.createElement(Wrapper, { headerHeight: headerHeight },
        isPC && (React.createElement(TitleRow, null,
            React.createElement(TitleWrapper, { align: "middle" },
                typeof title === 'string' ? React.createElement(TextComponent, { children: title, type: 'headline2' }) : title,
                guideName && React.createElement(GuideIcon, { guideName: guideName })),
            props.extra)),
        !isPC && props.extra && React.createElement(MobileExtraDiv, { top: headerHeight }, props.extra),
        React.createElement(Content, { headerHeight: headerHeight }, content)));
}
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 100%;\n  height: 100%;\n  // height: calc(100% - ", "px);\n  width: 100%;\n  padding: 0px;\n  background-color: ", ";\n"], ["\n  max-width: 100%;\n  height: 100%;\n  // height: calc(100% - ", "px);\n  width: 100%;\n  padding: 0px;\n  background-color: ", ";\n"])), function (props) { return props.headerHeight; }, COLORS.white);
var TitleRow = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  padding-right: 14px;\n"], ["\n  width: 100%;\n  padding-right: 14px;\n"])));
var TitleWrapper = styled(Row)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  padding-left: 14px;\n  padding-right: 14px;\n  padding-top: 12px;\n  padding-bottom: 12px;\n"], ["\n  flex: 1;\n  padding-left: 14px;\n  padding-right: 14px;\n  padding-top: 12px;\n  padding-bottom: 12px;\n"])));
var Content = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1;\n  height: 100%;\n  background-color: ", ";\n  overflow: hidden;\n  ", "\n"], ["\n  flex: 1;\n  height: 100%;\n  background-color: ", ";\n  overflow: hidden;\n  ", "\n"])), COLORS.white, MediaQuery.tabletWithMobile(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 100%;\n    overflow: scroll;\n  "], ["\n    width: 100%;\n    overflow: scroll;\n  "]))));
var MobileExtraDiv = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  position: absolute;\n  right: 10px;\n  top: ", "px;\n  z-index: 1;\n"], ["\n  display: flex;\n  align-items: center;\n  position: absolute;\n  right: 10px;\n  top: ", "px;\n  z-index: 1;\n"])), function (props) { return props.top + 5; });
export var GuideIcon = function (props) {
    var guides = StoreQuery.useServiceGuides();
    var guide = guides.data && guides.data[props.guideName];
    return guide ? (React.createElement(Dropdown, { dropdownRender: function () { return (React.createElement(CustomDropdownMenu, { items: guide.map(function (item, index) {
                var disabled = !item.url;
                return {
                    onClick: function () { return openWindow(item.url); },
                    label: React.createElement(UnderlineClickableText, { color: COLORS.gray1 }, "".concat(item.item, " >")),
                    name: item.item,
                    disabled: disabled
                };
            }) })); }, placement: "bottomRight", trigger: ['click'] },
        React.createElement(Image, { src: IMAGES.icon_help }))) : (React.createElement("div", null));
};
export var GuideIconOnlyLink = function (props) {
    return (React.createElement(Image, { src: IMAGES.icon_help, clickable: !!props.link, onClick: function (ev) {
            ev.stopPropagation();
            ev.preventDefault();
            if (props.link) {
                openWindow(props.link);
            }
        } }));
};
var Image = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-left: 5px;\n  height: 20px;\n  margin-top: 3px;\n  cursor: ", ";\n"], ["\n  margin-left: 5px;\n  height: 20px;\n  margin-top: 3px;\n  cursor: ", ";\n"])), function (props) { return (props.clickable ? 'pointer' : 'normal'); });
// Hook
export function useContentHeight(hasTab, hasPager) {
    var _a = useWindowSize(), isPC = _a.isPC, height = _a.height;
    var headerHeight = useRecoilValue(Atoms.globalHeaderHeightState);
    var PAGER_HEIGHT = hasPager ? 50 : 0;
    var TAB_HEIGHT = hasTab ? 75 : 0;
    return isPC ? height - PAGER_HEIGHT - 350 : height - PAGER_HEIGHT - headerHeight - TAB_HEIGHT;
}
export function useTableScroll(hasTab, hasPager) {
    var height = useContentHeight(hasTab, hasPager);
    var tableHeaderHeight = 80;
    return height - tableHeaderHeight;
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
