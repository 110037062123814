var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import * as ReactModal from 'react-modal';
import { atom, useRecoilState } from 'recoil';
import { COMMON_UI_COLORS, Icon, Layout, Typhography } from 'common-ui';
import { createNanoId } from 'common-utils';
var RECOIL_GLOBAL_MODAL_KEY = 'RECOIL_GLOBAL_MODAL_KEY';
var MODAL_MIN_Z_INDEX = 1000;
export var useModal = function () {
    var _a = useRecoilState(globalModalState), modals = _a[0], setModals = _a[1];
    var add = function (modal) {
        var id = createNanoId();
        setModals((modals || []).concat(__assign(__assign({}, modal), { id: id })));
        return id;
    };
    var remove = function (id) {
        if (id) {
            setModals(modals.filter(function (item) { return item.id === id; }));
        }
        else {
            setModals(modals.splice(-1));
        }
    };
    return { add: add, remove: remove };
};
export var GlobalModal = function (props) {
    var _a = useRecoilState(globalModalState), modals = _a[0], setModals = _a[1];
    var handleClose = function (index) {
        setModals(modals.filter(function (_, modalIndex) { return index !== modalIndex; }));
    };
    return (React.createElement(React.Fragment, null, modals.map(function (modal, index) { return (React.createElement(Modal, __assign({}, modal, { key: index, index: index, zIndex: MODAL_MIN_Z_INDEX + index, onClose: handleClose, isPC: props.isPC }))); })));
};
var Modal = function (props) {
    var ModalComponent = ReactModal;
    return (React.createElement(ModalComponent, { style: {
            overlay: {
                backgroundColor: 'rgba(97, 95, 104, 0.5)',
                zIndex: props.zIndex
            },
            content: __assign({ zIndex: props.zIndex, padding: '0px', border: '0', bottom: 'auto', minHeight: '10rem', left: '50%', position: 'fixed', right: 'auto', top: '50%', width: props.isPC ? '40%' : '80%', transform: 'translate(-50%,-50%)', maxWidth: '80%', background: COMMON_UI_COLORS.white, borderRadius: '0px' }, props.contentStyles)
        }, isOpen: true, 
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        // style={customStyles}
        contentLabel: "Example Modal" },
        React.createElement(ModalTitle, __assign({}, props)),
        React.createElement(ModalContent, __assign({}, props)),
        props.footer && React.createElement(ModalFooter, __assign({}, props))));
};
var ModalTitle = function (props) {
    return (React.createElement(Layout.FluidInline, { style: { height: '54px', padding: '24px' }, stretch: 0, align: "center" },
        typeof props.title === 'string' ? (React.createElement(Typhography.TextComponent, { children: props.title, type: "headline2", textAlign: "center" })) : (props.title),
        React.createElement(Icon.ClickableIcon, { name: "close", size: 30, onClick: function () { return typeof props.onClose === 'function' && props.onClose(Number((props === null || props === void 0 ? void 0 : props.index) || 0)); } })));
};
var ModalContent = function (props) {
    return (React.createElement(Layout.FluidInline, { style: { padding: '24px', borderTop: "1px solid ".concat(COMMON_UI_COLORS.gray6) }, stretch: 0, align: "center" }, props.content || ''));
};
var ModalFooter = function (props) {
    return (React.createElement(Layout.FluidInline, { style: { padding: '12px 24px', borderTop: "1px solid ".concat(COMMON_UI_COLORS.gray6) }, stretch: 0, align: "center" }, props.footer || ''));
};
var globalModalState = atom({
    key: RECOIL_GLOBAL_MODAL_KEY,
    default: []
});
