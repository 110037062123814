/**
 * TODO:
 * 1. 스타일 시트 분리
 * 2. 텍스트 스타일 시트로 정리
 * 3. 원래 기능 복구 AppLayoutBackup.tsx
 */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { TextComponent, SidebarTop, ProfileItem, NotificationCircle, useGlobalModal, GuideIcon, Icon, CustomerSearchInput, QuickMenu, StickBanner, QuickMenuButton, HelpCenterButton, HelpCenter, ScrollBarWithMeasure } from 'renderer/components';
import { useWindowSize, useLocalStorage } from 'renderer/utils';
import { COLORS, MediaQuery } from 'renderer/styles';
import { Col, Row } from 'antd/lib/grid';
import Layout from 'antd/lib/layout';
import { Layout as LayoutComponent } from 'common-ui';
import Menu from 'antd/lib/menu';
import { PATH_APP_RESERVATION, getLogoImage, LOCAL_STORAGE_KEY_SIDEMENU, LOCAL_STORAGE_KEY_QUICKMENU } from 'renderer/constants';
import Drawer from 'antd/lib/drawer';
import { useRecoilState } from 'recoil';
import { Atoms } from 'renderer/stores';
import styled, { css } from 'styled-components';
export function AppLayout(props) {
    var menus = props.menus, body = props.body, manager = props.manager;
    var _a = useWindowSize(), device = _a.device, isPC = _a.isPC;
    var isAdmin = manager.isSuperAdmin || manager.isFranchiseAdmin;
    var _b = useLocalStorage(LOCAL_STORAGE_KEY_SIDEMENU, isPC, {
        store: true,
        manager: true
    }), open = _b[0], setOpen = _b[1];
    var _c = useLocalStorage(LOCAL_STORAGE_KEY_QUICKMENU, isPC && !isAdmin ? 'quick' : 'help', {
        store: true,
        manager: true
    }), quickmenuOpen = _c[0], setQuickmenuOpen = _c[1];
    var rightSidebar = quickmenuOpen === true ? 'quick' : quickmenuOpen;
    var naviate = useNavigate();
    var location = useLocation();
    var handleSideMenuChange = function () {
        setOpen(!open);
    };
    var handleGoHome = function () {
        if (!isAdmin) {
            setCurrentPath(PATH_APP_RESERVATION);
            naviate(PATH_APP_RESERVATION, { replace: true });
        }
    };
    var _d = React.useState(location.pathname), currentPath = _d[0], setCurrentPath = _d[1];
    var handleClickMenu = function (menu) {
        naviate(menu.path);
        setCurrentPath(menu.path);
        if (device !== 'pc') {
            handleSideMenuChange();
        }
    };
    var handleSidebarOpen = function (quick) {
        if (rightSidebar) {
            handleSidebarClose();
        }
        else {
            setQuickmenuOpen(quick);
        }
    };
    var handleSidebarClose = function () {
        setQuickmenuOpen(null);
    };
    var globalHeader = useRecoilState(Atoms.globalHeaderState)[0];
    return (React.createElement(LayoutComponent.AppLayout, { sidebarOpen: open, onSideOpenChange: handleSideMenuChange, isPC: isPC, main: {
            children: (React.createElement(React.Fragment, null,
                props.store && React.createElement(StickBanner, { store: props.store }),
                React.createElement(SplitWrapper, null,
                    React.createElement(Main, { quickmenuOpen: !isAdmin ? rightSidebar : false, isPC: isPC }, body),
                    !isAdmin &&
                        rightSidebar &&
                        (rightSidebar === 'quick' ? (React.createElement(QuickMenu, { isPC: isPC, handleClose: handleSidebarClose, store: props.store, visible: rightSidebar === 'quick' })) : (React.createElement(HelpCenter, { isPC: isPC, handleClose: handleSidebarClose, store: props.store, visible: rightSidebar === 'help' }))))))
        }, sidebar: {
            children: isPC ? (React.createElement(SideMenuWrapper, null,
                React.createElement(SideMenuContent, { manager: props.manager, currentPath: currentPath, onClickMenu: handleClickMenu, menus: menus, store: props.store }))) : null
        }, header: {
            children: (React.createElement(Row, { justify: "space-between", align: "middle", gutter: 16, wrap: false },
                React.createElement(Col, { flex: 1 },
                    React.createElement(Row, { align: "middle", wrap: false }, isPC || !globalHeader ? (React.createElement(LogoImage, { src: getLogoImage(props.franchiseId), onClick: handleGoHome })) : (React.createElement(Row, { align: "middle", wrap: false },
                        typeof globalHeader.title === 'string' ? (React.createElement(TextComponent, { children: globalHeader.title, type: globalHeader.title.length > 6 ? 'headline3' : 'headline3' })) : (globalHeader.title),
                        globalHeader.guideName && React.createElement(GuideIcon, { guideName: globalHeader.guideName }))))),
                React.createElement(Col, null,
                    React.createElement(Row, { align: "middle", justify: "end", gutter: isPC ? 16 : 8 }, !isAdmin && React.createElement(HeaderSearcher, { store: props.store }))),
                React.createElement(Col, null,
                    React.createElement(Row, { align: "middle", justify: "end", gutter: isPC ? 16 : 8, wrap: false },
                        !isAdmin && React.createElement(HelpCenterButton, { onClick: function () { return handleSidebarOpen('help'); } }),
                        !isAdmin && React.createElement(QuickMenuButton, { handleOpen: function () { return handleSidebarOpen('quick'); } }),
                        !isAdmin && (React.createElement(Col, null,
                            React.createElement(NotificationCircle, { manager: props.manager }))),
                        isAdmin && (React.createElement(Col, null,
                            React.createElement(ProfileItem, { manager: props.manager }))))),
                !isPC && (React.createElement(MobileSideMenu, { title: null, closeIcon: null, placement: "left", open: open, width: 240, onClose: handleSideMenuChange, children: React.createElement(SideMenuContent, { manager: props.manager, currentPath: currentPath, onClickMenu: handleClickMenu, menus: menus, store: props.store }) }))))
        } }));
}
export var SideMenuContent = function (props) {
    var currentPath = props.currentPath, onClickMenu = props.onClickMenu, store = props.store, menus = props.menus;
    var renderMenu = function (menu) {
        var isActive = currentPath.includes(menu.path);
        return (React.createElement(SideMenuItem, { "$isActive": isActive, key: menu.id, onClick: function () { return onClickMenu(menu); } },
            typeof menu.icon === 'string' ? (React.createElement(SideMenuIcon, { size: 20, color: isActive ? COLORS.primary1 : COLORS.black, name: menu.icon })) : (
            // FIXME
            React.createElement("svg", { width: "26", height: "24", viewBox: "0 0 26 24", xmlns: "http://www.w3.org/2000/svg", style: { marginRight: '15px', marginLeft: '8px' } },
                React.createElement("g", { transform: "translate(1.5 2.5)", stroke: isActive ? '#9675f8' : '#27262A', strokeWidth: "1.8", fill: "none", fillRule: "evenodd" },
                    React.createElement("path", { d: "M13.91 4.112a8.364 8.364 0 1 0 3.02 4.385" }),
                    React.createElement("circle", { cx: "8.886", cy: "10.8", r: "4.327" }),
                    React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "m8.886 10.8 10.112-7.055M13.852 7.091V3.6L18.818 0v3.6M13.993 7.368l3.6 1.12 4.874-3.367-3.649-1.23" })))),
            React.createElement(TextComponent, { marginLeft: 0, id: menu.label, type: "subtitle1", color: isActive ? COLORS.primary1 : COLORS.black })));
    };
    return (React.createElement(SideMenu, null,
        React.createElement(StyledScrollBarWithMeasure, { additionalHeight: 5, options: {
                overflowBehavior: {
                    x: 'hidden'
                }
            } },
            React.createElement(SideMenuContentWrapper, null,
                props.store && React.createElement(SidebarTop, { store: props.store, onClickMenu: props.onClickMenu }),
                props.store && React.createElement(Divider, null),
                menus.map(function (menu) {
                    return renderMenu(menu);
                }))),
        React.createElement(ProfileItem, { manager: props.manager })));
};
var StyledScrollBarWithMeasure = styled(ScrollBarWithMeasure)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: calc(100% - 60px);\n  width: 220px;\n"], ["\n  height: calc(100% - 60px);\n  width: 220px;\n"])));
var SideMenuContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: calc(100% - 60px);\n  width: 220px;\n"], ["\n  height: calc(100% - 60px);\n  width: 220px;\n"])));
var Main = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100%;\n  width: ", ";\n  & > * {\n    height: 100%;\n    width: 100%;\n  }\n"], ["\n  height: 100%;\n  width: ", ";\n  & > * {\n    height: 100%;\n    width: 100%;\n  }\n"])), function (props) {
    return props.quickmenuOpen && props.isPC ? "calc(100% - ".concat(props.quickmenuOpen === 'quick' ? 360 : 720, "px)") : '100%';
});
var SplitWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n"], ["\n  display: flex;\n  width: 100%;\n"])));
var Divider = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  border-top: 1px solid ", ";\n  margin-top: 20px;\n  margin-bottom: 20px;\n"], ["\n  width: 100%;\n  border-top: 1px solid ", ";\n  margin-top: 20px;\n  margin-bottom: 20px;\n"])), COLORS.gray6);
var SideMenu = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n"])));
var SideMenuIcon = styled(Icon)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-left: 8px;\n  margin-right: 22px;\n"], ["\n  margin-left: 8px;\n  margin-right: 22px;\n"])));
var SideMenuItem = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 0 14px;\n  height: 36px;\n  margin-bottom: 2px;\n  border-radius: 2px;\n  display: flex;\n  align-items: center;\n  width: 192px;\n  background-color: ", ";\n  cursor: pointer;\n  :hover {\n    background-color: ", ";\n  }\n"], ["\n  margin: 0 14px;\n  height: 36px;\n  margin-bottom: 2px;\n  border-radius: 2px;\n  display: flex;\n  align-items: center;\n  width: 192px;\n  background-color: ", ";\n  cursor: pointer;\n  :hover {\n    background-color: ", ";\n  }\n"])), function (props) { return (props.$isActive ? COLORS.primary5 : COLORS.white); }, COLORS.gray7);
var MobileSideMenu = styled(Drawer)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  & .ant-drawer-body {\n    padding: 0;\n  }\n"], ["\n  & .ant-drawer-body {\n    padding: 0;\n  }\n"])));
var SideMenuWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background-color: var(--colorWhite);\n  padding: 0px;\n  height: 100%;\n"], ["\n  background-color: var(--colorWhite);\n  padding: 0px;\n  height: 100%;\n"])));
export var InitialLayout = styled(Layout)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  height: 100vh;\n"], ["\n  height: 100vh;\n"])));
export var LogoImage = styled.img(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 100px;\n  cursor: pointer;\n\n  ", "\n"], ["\n  width: 100px;\n  cursor: pointer;\n\n  ", "\n"])), MediaQuery.tabletWithMobile(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    width: 80px;\n  "], ["\n    width: 80px;\n  "]))));
export var MenuImage = styled.img(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  width: 20px;\n"], ["\n  width: 20px;\n"])));
export var LineMenuIcon = styled(Icon)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  margin-right: 15px;\n  cursor: pointer;\n\n  ", "\n"], ["\n  margin-right: 15px;\n  cursor: pointer;\n\n  ", "\n"])), MediaQuery.tabletWithMobile(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  "], ["\n  "]))));
export var TopMenuIconWrapper = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  background-color: ", ";\n  width: 26px;\n  height: 26px;\n  padding: 3px;\n  margin-right: 20px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border-radius: 4px;\n  cursor: pointer;\n"], ["\n  background-color: ", ";\n  width: 26px;\n  height: 26px;\n  padding: 3px;\n  margin-right: 20px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border-radius: 4px;\n  cursor: pointer;\n"])), function (props) { return props.backgroundColor; });
export var StyledMenuItem = styled(function (_a) {
    var $isActive = _a.$isActive, rest = __rest(_a, ["$isActive"]);
    return React.createElement(Menu.Item, __assign({}, rest));
})(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  margin: 0px 10px;\n  &.ant-menu-item-selected {\n    margin: 0px 10px;\n  }\n  &.ant-menu-item:active .ant-menu-submenu-title:active {\n    background-color: transparent !important;\n  }\n  background-color: ", " !important;\n\n  &:hover {\n    & svg {\n      color: ", " !important;\n    }\n    & > span > div {\n      color: ", " !important;\n    }\n  }\n"], ["\n  margin: 0px 10px;\n  &.ant-menu-item-selected {\n    margin: 0px 10px;\n  }\n  &.ant-menu-item:active .ant-menu-submenu-title:active {\n    background-color: transparent !important;\n  }\n  background-color: ", " !important;\n\n  &:hover {\n    & svg {\n      color: ", " !important;\n    }\n    & > span > div {\n      color: ", " !important;\n    }\n  }\n"])), function (props) { return (props.$isActive ? COLORS.primary4 : COLORS.white); }, COLORS.primary1, COLORS.primary1);
export var GoHomeDiv = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  margin-top: 36px;\n  margin-left: 10px;\n  margin-bottom: 18px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n\n  & > span {\n    font-size: 18px;\n    color: var(--colorGray);\n    font-weight: 800;\n  }\n"], ["\n  margin-top: 36px;\n  margin-left: 10px;\n  margin-bottom: 18px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n\n  & > span {\n    font-size: 18px;\n    color: var(--colorGray);\n    font-weight: 800;\n  }\n"])));
var HeaderSearcher = function (props) {
    var customerModal = useGlobalModal();
    var handleSelectedCustomerId = function (customerId) {
        customerModal.open({ type: 'customerDetail', data: { customerId: customerId } });
        setIsFocused(false);
    };
    var _a = React.useState(false), isFocused = _a[0], setIsFocused = _a[1];
    var _b = useWindowSize(), isPC = _b.isPC, width = _b.width;
    var inputWidth = isPC ? 250 : isFocused ? width - 24 : 45;
    return (React.createElement(StyledHeaderSearcher, { onChange: handleSelectedCustomerId, onSelect: handleSelectedCustomerId, onDropdownVisibleChange: function (open) {
            setIsFocused(open);
        }, value: null, width: inputWidth, "$isFocused": isFocused, "$isPC": isPC, placeholder: React.createElement(React.Fragment, null,
            React.createElement(Icon, { name: "search", color: COLORS.gray1, size: 24 }),
            "\u00A0 \uACE0\uAC1D\uBA85 \uB610\uB294 \uC5F0\uB77D\uCC98 \uAC80\uC0C9"), dropdownMatchSelectWidth: inputWidth }));
};
var StyledHeaderSearcher = styled(CustomerSearchInput)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  & .ant-select-selector {\n    border-radius: 20px !important;\n    background-color: var(--gray-6) !important;\n    border: 0px solid var(--gray-6) !important;\n    height: 40px !important;\n    width: ", "px !important;\n    transition: width 1s;\n    ", "\n  }\n\n  & .ant-select-arrow {\n    display: none;\n  }\n\n  & .ant-select-selection-placeholder {\n    display: flex;\n    align-items: center;\n\n    & svg {\n      overflow: visible;\n    }\n  }\n\n  & .ant-select-selection-item {\n    display: flex;\n    align-items: center;\n  }\n\n  & input {\n    height: 40px !important;\n  }\n"], ["\n  & .ant-select-selector {\n    border-radius: 20px !important;\n    background-color: var(--gray-6) !important;\n    border: 0px solid var(--gray-6) !important;\n    height: 40px !important;\n    width: ", "px !important;\n    transition: width 1s;\n    ", "\n  }\n\n  & .ant-select-arrow {\n    display: none;\n  }\n\n  & .ant-select-selection-placeholder {\n    display: flex;\n    align-items: center;\n\n    & svg {\n      overflow: visible;\n    }\n  }\n\n  & .ant-select-selection-item {\n    display: flex;\n    align-items: center;\n  }\n\n  & input {\n    height: 40px !important;\n  }\n"])), function (props) { return props.width; }, function (props) {
    return !props.$isPC && props.$isFocused
        ? css(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n            position: fixed !important;\n            left: 12px;\n            top: 8px;\n            z-index: 9999;\n            transition: width 1s;\n          "], ["\n            position: fixed !important;\n            left: 12px;\n            top: 8px;\n            z-index: 9999;\n            transition: width 1s;\n          "]))) : null;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
