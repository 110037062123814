var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Types } from 'common-type';
import React from 'react';
import { COMMON_UI_COLORS, Layout, Typhography, Icon } from 'common-ui';
import styled from 'styled-components';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import isEmpty from 'lodash/isEmpty';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import * as ReactModal from 'react-modal';
import { SVGIcons } from '../images';
export var ICONS = {
    phone: React.createElement(SVGIcons.SocialPhone, null),
    email: React.createElement(SVGIcons.SocialMessage, null),
    instagram: React.createElement(SVGIcons.SocialInstagram, null),
    youtube: React.createElement(SVGIcons.SocialYoutube, null),
    twiter: React.createElement(SVGIcons.SocialTwitter, null),
    kakaotalk: React.createElement(SVGIcons.SocialKakaotalk, null),
    naver: React.createElement(SVGIcons.SocialNaver, null),
    naverblog: React.createElement(SVGIcons.SocialNaver, null),
    navercarfe: React.createElement(SVGIcons.SocialNaverCafe, null),
    tistory: React.createElement(SVGIcons.SocialTiStory, null),
    facebook: React.createElement(SVGIcons.SocialFacebook, null),
    kakaostory: React.createElement(SVGIcons.SocialKakaoStory, null),
    homepage: React.createElement(SVGIcons.SocialHomepage, null)
};
// const ICONS = {};
export var GrayCircleIconWithProvider = function (props) {
    return (React.createElement(Icon.Circle, { color: COMMON_UI_COLORS.gray7, width: 28, onClick: props.onClick, style: { width: '28px' } }, ICONS[props.provider]));
};
export var BlackIcon = function (props) {
    var _a = props.name, name = _a === void 0 ? 'text' : _a;
    var imageObject = {
        text: SVGIcons.TextBlock,
        profile: SVGIcons.ProfileBlock,
        link: SVGIcons.LinkBlock,
        sns: SVGIcons.SNSBlock,
        image: SVGIcons.GalleryBlock
    };
    return React.createElement(Icon.Rectangle, { style: props.style }, React.createElement(imageObject[name]));
};
export var PageContainer = function (props) {
    var _a = props.data || {}, blocks = _a.blocks, theme = _a.theme, pageName = _a.pageName;
    var profile = (props.data.blocks || [])[0];
    var handleClickBlock = function (id) {
        if (props.onClickButton) {
            props.onClickButton(id);
        }
        else {
            var item = blocks.filter(Boolean).find(function (block) { return block.id === id; });
            if (item === null || item === void 0 ? void 0 : item.link) {
                window.open(item === null || item === void 0 ? void 0 : item.link);
            }
        }
    };
    return (React.createElement(Wrapper, { theme: theme, isDev: props.isDev },
        React.createElement("div", null),
        React.createElement(Layout.FluidStack, { style: {
                maxWidth: '320px',
                margin: '30px 20px'
            }, gutter: 18 },
            React.createElement(ProfileAvatar, __assign({}, props, { profile: profile })),
            blocks
                .map(function (block, index) {
                if (index === 0 || !block.active) {
                    return;
                }
                else {
                    if (block.type === Types.PageModel.TYPES.TEXT) {
                        return React.createElement(TextBlock, __assign({}, props, { block: block, key: block.id || index, onClickButton: handleClickBlock }));
                    }
                    else if (block.type === Types.PageModel.TYPES.LINK) {
                        return React.createElement(LinkBlock, __assign({}, props, { block: block, key: block.id || index, onClickButton: handleClickBlock }));
                    }
                    else if (block.type === Types.PageModel.TYPES.IMAGE) {
                        return React.createElement(ImageBlock, __assign({}, props, { block: block, key: block.id || index, onClickButton: handleClickBlock }));
                    }
                    else if (block.type === Types.PageModel.TYPES.SNS) {
                        return React.createElement(SNSBlock, __assign({}, props, { block: block, key: block.id || index, onClickButton: handleClickBlock }));
                    }
                    else {
                        return;
                    }
                }
            })
                .filter(Boolean)),
        React.createElement(Footer, { theme: theme })));
};
var Footer = function (props) {
    var itemProps = {
        style: { cursor: 'pointer', marginBottom: '30px' },
        onClick: function () { return window.open('https://thinkofyou.kr'); }
    };
    return props.theme === 'dark' ? (React.createElement(SVGIcons.DarkHorizontalLogo, __assign({}, itemProps))) : (React.createElement(SVGIcons.LightHorizontalLogo, __assign({}, itemProps)));
};
var ProfileAvatar = function (props) {
    var theme = (props.data || {}).theme;
    var profile = props.profile;
    var isValidImage = (profile === null || profile === void 0 ? void 0 : profile.image) && typeof profile.image === 'string';
    return (React.createElement(BlockInWrapper, { onClick: function () { return props.onClickButton && props.onClickButton(props.profile.id); } },
        React.createElement(Layout.FluidInline, { justify: "center" }, isValidImage ? (React.createElement(ProfileImage, { src: profile.image })) : theme === 'dark' ? (React.createElement(SVGIcons.ProfileBlack, null)) : (React.createElement(SVGIcons.ProfileWhite, null))),
        React.createElement(Typhography.TextComponent, { marginTop: 20, type: "headline3", children: profile === null || profile === void 0 ? void 0 : profile.title, textAlign: "center" }),
        React.createElement(Typhography.TextComponent, { type: "subtitle3", marginTop: 10, children: profile === null || profile === void 0 ? void 0 : profile.description, textAlign: "center" })));
};
var TextBlock = function (props) {
    var theme = (props.data || {}).theme;
    var text = props.block;
    var isValid = !!text.title;
    return !isValid && !props.isDev ? (React.createElement("div", null)) : (React.createElement(BlockInWrapper, { onClick: function () { return props.onClickButton && props.onClickButton(props.block.id); } }, isValid ? (React.createElement(Layout.FluidStack, null,
        React.createElement(Typhography.TextComponent, { type: "subtitle2", textAlign: text.align, children: text.title }),
        text.description && (React.createElement(Typhography.TextComponent, { type: "caption1", children: text.description, textAlign: text.align })))) : (React.createElement(BlockPlaceholderWrapper, { theme: theme, type: "text" }))));
};
var LinkBlock = function (props) {
    var _a = props.data || {}, theme = _a.theme, color = _a.color;
    var block = props.block;
    var isValid = !!block.title && !!block.link;
    var _b = useSpring(function () { return ({
        opacity: 0,
        transform: 'scale(1, 1)',
        fillSize: 0,
        borderRadius: 200
    }); }), _c = _b[0], fillSize = _c.fillSize, transform = _c.transform, opacity = _c.opacity, borderRadius = _c.borderRadius, set = _b[1];
    var image = block.image;
    var isHorizontal = block.layout === 'horizontal';
    var isValidImage = image && typeof image === 'string';
    return !isValid && !props.isDev ? (React.createElement("div", null)) : (React.createElement(BlockInWrapper, { onClick: function () {
            var _a;
            if (props.onClickButton) {
                props.onClickButton && props.onClickButton(props.block.id);
            }
            else {
                window.open((_a = props.block) === null || _a === void 0 ? void 0 : _a.link);
            }
        } }, isValid ? (React.createElement(animated.button, { onMouseEnter: function () {
            return set({
                transform: 'scale(1.02, 1.02)',
                fillSize: 700,
                opacity: 0.2
            });
        }, onMouseLeave: function () {
            return set({
                transform: 'scale(1, 1)',
                fillSize: 0,
                opacity: 0
            });
        }, style: {
            border: 0,
            transform: transform,
            height: isValidImage ? (isHorizontal ? '74px' : '288px') : '48px',
            cursor: 'pointer',
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: isHorizontal ? 'row' : 'column',
            alignItems: 'center',
            padding: '0px',
            backgroundColor: color,
            color: COMMON_UI_COLORS.white,
            justifyContent: !isValidImage ? 'center' : 'start'
        } },
        isValidImage && !isHorizontal && React.createElement(VerticalLinkImage, { src: image }),
        isValidImage && isHorizontal && React.createElement(HorizonLinkImage, { src: image }),
        React.createElement(Typhography.TextComponent, { children: block.title, marginLeft: 10, marginTop: isValidImage && !isHorizontal ? 12 : 0, textAlign: "center", color: COMMON_UI_COLORS.white }))) : props.isDev ? (React.createElement(BlockPlaceholderWrapper, { theme: theme, type: "link" })) : (React.createElement("div", null))));
};
var HorizonLinkImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 50px;\n  height: 50px;\n  border-radius: 4px;\n  object-fit: cover;\n  margin-left: 10px;\n"], ["\n  width: 50px;\n  height: 50px;\n  border-radius: 4px;\n  object-fit: cover;\n  margin-left: 10px;\n"])));
var VerticalLinkImage = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 320px;\n  height: 240px;\n  object-fit: cover;\n"], ["\n  width: 320px;\n  height: 240px;\n  object-fit: cover;\n"])));
var BlockPlaceholderWrapper = function (props) {
    var typeNames = {
        text: '텍스트',
        image: '이미지',
        link: '링크',
        sns: 'SNS'
    };
    return (React.createElement(BlockPlaceholder, { theme: props.theme },
        React.createElement(BlackIcon, { name: props.type, style: { backgroundColor: COMMON_UI_COLORS.gray3, marginLeft: '12px' } }),
        React.createElement(Typhography.TextComponent, { marginLeft: 10, children: "".concat(typeNames[props.type], " \uBE14\uB85D\uC774 \uC0DD\uC131\uB420 \uC704\uCE58\uC785\uB2C8\uB2E4.") })));
};
var BlockPlaceholder = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 48px;\n  border-radius: 4px;\n  border: dashed 1px ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 48px;\n  border-radius: 4px;\n  border: dashed 1px ", ";\n"])), function (props) { return (props.theme === 'dark' ? COMMON_UI_COLORS.gray3 : COMMON_UI_COLORS.black); });
var ProfileImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 80px;\n  height: 80px;\n  border-radius: 50%;\n"], ["\n  width: 80px;\n  height: 80px;\n  border-radius: 50%;\n"])));
var Wrapper = styled(Layout.FluidStack)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  min-height: 100%;\n  background-color: ", ";\n  width: 100%;\n  color: ", " !important;\n  overflow: hidden;\n  border-radius: ", ";\n\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  & * {\n    color: ", ";\n  }\n"], ["\n  min-height: 100%;\n  background-color: ", ";\n  width: 100%;\n  color: ", " !important;\n  overflow: hidden;\n  border-radius: ", ";\n\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  & * {\n    color: ", ";\n  }\n"])), function (props) { return (props.theme === 'dark' ? COMMON_UI_COLORS.black : COMMON_UI_COLORS.white); }, function (props) { return (props.theme === 'dark' ? COMMON_UI_COLORS.white : COMMON_UI_COLORS.black); }, function (props) { return (props.isDev ? '10px' : '0px'); }, function (props) { return (props.theme === 'dark' ? COMMON_UI_COLORS.white : COMMON_UI_COLORS.black); });
var BlockInWrapper = styled(Layout.FluidStack).attrs({ justify: 'center' })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: calc(100%);\n  white-space: pre-wrap;\n"], ["\n  width: calc(100%);\n  white-space: pre-wrap;\n"])));
var ImageBlock = function (props) {
    var _a;
    var theme = (props.data || {}).theme;
    var block = props.block;
    var images = ((_a = block.images) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return !!item && typeof item === 'string'; })) || [];
    var isValid = !isEmpty(images);
    var imagesArray = [];
    if (images.length === 0) {
    }
    else {
        if (images.length === 1) {
            imagesArray = [[images[0]]];
        }
        else if (images.length === 2) {
            imagesArray = [[images[0], images[1]]];
        }
        else if (images.length === 3) {
            imagesArray = [[images[0], images[1], images[2]]];
        }
        else if (images.length === 4) {
            imagesArray = [
                [images[0], images[1]],
                [images[2], images[3]]
            ];
        }
        else if (images.length === 5) {
            imagesArray = [
                [images[0], images[1]],
                [images[2], images[3], images[4]]
            ];
        }
        else if (images.length === 6) {
            imagesArray = [
                [images[0], images[1], images[2]],
                [images[3], images[4], images[5]]
            ];
        }
        else if (images.length === 7) {
            imagesArray = [
                [images[0], images[1]],
                [images[2], images[3]],
                [images[4], images[5], images[6]]
            ];
        }
        else if (images.length === 8) {
            imagesArray = [
                [images[0], images[1]],
                [images[2], images[3], images[4]],
                [images[4], images[5], images[6]]
            ];
        }
        else if (images.length === 9) {
            imagesArray = [
                [images[0], images[1], images[2]],
                [images[3], images[4], images[5]],
                [images[6], images[7], images[8]]
            ];
        }
        else if (images.length === 10) {
            imagesArray = [
                [images[0], images[1]],
                [images[2], images[3]],
                [images[4], images[5], images[6]],
                [images[7], images[8], images[9]]
            ];
        }
    }
    var _b = React.useState(), startIndex = _b[0], setStartIndex = _b[1];
    return !isValid && !props.isDev ? (React.createElement("div", null)) : (React.createElement(BlockInWrapper, { onClick: function () {
            if (props.onClickButton) {
                props.onClickButton(props.block.id);
            }
        } }, isValid ? (React.createElement(React.Fragment, null,
        block.layout === 'full' ? (React.createElement(StyledImageGallery, null,
            React.createElement(ImageGallery, { renderItem: function (item) {
                    return (React.createElement(ImageItem, __assign({}, item, { key: item.original, onClick: function () {
                            if (!props.onClickButton) {
                                setStartIndex(images.findIndex(function (imageUrl) { return imageUrl === item.original; }));
                            }
                        } })));
                }, renderLeftNav: function (onClick, disabled) {
                    return disabled ? (React.createElement("div", null)) : (React.createElement(Chevron, { direction: "left" },
                        React.createElement(Icon.Icon, { name: "left-chevron", onClick: onClick, color: COMMON_UI_COLORS.gray2, size: 24 })));
                }, renderRightNav: function (onClick, disabled) {
                    return disabled ? (React.createElement("div", null)) : (React.createElement(Chevron, { direction: "right" },
                        React.createElement(Icon.Icon, { name: "right-chevron", onClick: onClick, color: COMMON_UI_COLORS.gray2, size: 24 })));
                }, showFullscreenButton: false, showPlayButton: false, showThumbnails: false, items: images.map(function (item) {
                    return {
                        original: item,
                        thumbnail: item
                    };
                }) }))) : (React.createElement(Layout.FluidStack, null, images.length === 1 ? (React.createElement(FullSizeImages, { src: images[0] })) : (imagesArray.map(function (arr, index) {
            return (React.createElement(Layout.FluidColumns, { columns: arr.length, key: index, gutter: 8 }, arr.map(function (item, itemIndex) {
                return (React.createElement(Layout.Column, { key: "".concat(index, "_").concat(itemIndex) },
                    React.createElement(ImagesInColumn, { src: item, columns: arr.length, onClick: function () {
                            if (!props.onClickButton) {
                                setStartIndex(images.findIndex(function (imageUrl) { return imageUrl === item.original; }));
                            }
                        } })));
            })));
        })))),
        React.createElement(StyledModal, { style: {
                overlay: {
                    zIndex: 9999
                },
                content: {
                    backgroundColor: 'rgba(97, 95, 104, 0.8)',
                    padding: '0px',
                    inset: 0,
                    border: '0',
                    borderRadius: '0px',
                    height: '100vh',
                    width: '100vw'
                }
            }, isOpen: typeof startIndex === 'number', 
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            // style={customStyles}
            contentLabel: "Example Modal" },
            React.createElement(Icon.ClickableIcon, { name: "x", onClick: function () { return setStartIndex(undefined); }, color: COMMON_UI_COLORS.white, size: 50, style: {
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                    zIndex: '9999'
                } }),
            React.createElement(ImageGallery, { startIndex: startIndex, showFullscreenButton: false, showPlayButton: false, items: images.map(function (item) {
                    return {
                        original: item,
                        thumbnail: item
                    };
                }) })))) : (React.createElement(BlockPlaceholderWrapper, { theme: theme, type: "image" }))));
};
var SNSBlock = function (props) {
    var theme = (props.data || {}).theme;
    var block = props.block;
    var isValid = !isEmpty(block.list);
    return !isValid && !props.isDev ? (React.createElement("div", null)) : (React.createElement(BlockInWrapper, null, isValid ? (React.createElement(SNSInline, { justify: "center", gutter: 10 }, block.list.map(function (item, index) {
        return (React.createElement(GrayCircleIconWithProvider, { onClick: function () {
                if (props.onClickButton && props.isDev) {
                    props.onClickButton(props.block.id);
                }
                else {
                    if (item.provider === 'phone' && item.link) {
                        document.location.href = 'tel:' + item.link;
                    }
                    else {
                        window.open(item.link);
                    }
                }
            }, provider: item.provider, key: "".concat(item.provider, "_").concat(index) }));
    }))) : (React.createElement(BlockPlaceholderWrapper, { theme: theme, type: "sns" }))));
};
var SNSInline = styled(Layout.FluidInline)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex-wrap: wrap;\n"], ["\n  flex-wrap: wrap;\n"])));
var Chevron = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  ", "\n  top: 105px;\n  font-size: 100px;\n  z-index: 9999;\n  color: white;\n  cursor: pointer;\n  width: 28px;\n  height: 28px;\n  padding: 4px;\n  border-radius: 50%;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  position: absolute;\n  ", "\n  top: 105px;\n  font-size: 100px;\n  z-index: 9999;\n  color: white;\n  cursor: pointer;\n  width: 28px;\n  height: 28px;\n  padding: 4px;\n  border-radius: 50%;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), function (props) { return (props.direction === 'right' ? 'right: 10px;' : 'left: 10px;'); }, COMMON_UI_COLORS.white);
// const StyledGrayCircleIconWithProvider = styled(GrayCircleIconWithProvider)`
//   cursor: pointer;
// `;
var StyledModal = styled(ReactModal)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  .image-gallery {\n    width: 100%;\n    height: 100%;\n  }\n"], ["\n  .image-gallery {\n    width: 100%;\n    height: 100%;\n  }\n"])));
var StyledImageGallery = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  .image-gallery {\n    width: 320px;\n    height: 320px;\n  }\n\n  & img {\n    width: 320px;\n    height: 320px;\n    cursor: pointer;\n  }\n\n  & .image-gallery-icon {\n    & > svg {\n      width: 60px;\n      height: 60px;\n    }\n\n    & .image-gallery-left-nav {\n      padding: 50px 0px;\n    }\n  }\n"], ["\n  .image-gallery {\n    width: 320px;\n    height: 320px;\n  }\n\n  & img {\n    width: 320px;\n    height: 320px;\n    cursor: pointer;\n  }\n\n  & .image-gallery-icon {\n    & > svg {\n      width: 60px;\n      height: 60px;\n    }\n\n    & .image-gallery-left-nav {\n      padding: 50px 0px;\n    }\n  }\n"])));
var StyledImage = styled.img(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  border-radius: 4px;\n  cursor: pointer;\n"], ["\n  border-radius: 4px;\n  cursor: pointer;\n"])));
var ImagesInColumn = styled(StyledImage)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 100%;\n  height: ", "px;\n  object-fit: cover;\n"], ["\n  width: 100%;\n  height: ", "px;\n  object-fit: cover;\n"])), function (props) { return 320 / props.columns; });
var FullSizeImages = styled(StyledImage)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 320px;\n  height: 320px;\n"], ["\n  width: 320px;\n  height: 320px;\n"])));
// https://github.com/xiaolin/react-image-gallery/blob/513787146c001eaf093b11f65d1c3f745af88e73/src/Item.js#L4:7
var ImageItem = React.memo(function (props) {
    var description = props.description, fullscreen = props.fullscreen, // fullscreen version of img
    handleImageLoaded = props.handleImageLoaded, isFullscreen = props.isFullscreen, onImageError = props.onImageError, original = props.original, originalAlt = props.originalAlt, originalHeight = props.originalHeight, originalWidth = props.originalWidth, originalTitle = props.originalTitle, sizes = props.sizes, srcSet = props.srcSet, loading = props.loading;
    var itemSrc = isFullscreen ? fullscreen || original : original;
    return (React.createElement(React.Fragment, null,
        React.createElement("img", { onClick: props.onClick, className: "image-gallery-image", src: itemSrc, alt: originalAlt, srcSet: srcSet, height: originalHeight, width: originalWidth, sizes: sizes, title: originalTitle, onLoad: function (event) { return handleImageLoaded && handleImageLoaded(event, original); }, onError: onImageError, loading: loading }),
        description && React.createElement("span", { className: "image-gallery-description" }, description)));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
