export var COMMON_UI_COLORS = {
    calendarRedActive: '#ea3e3e',
    calendarRedDisabled: '#ffaaaa',
    black: '#1F1F1F',
    gray1: '#595959',
    gray2: '#8C8C8C',
    gray3: '#BFBFBF',
    gray4: '#D9D9D9',
    gray4f: '#D9D9D9',
    gray5: '#F0F0F0',
    gray5f: '#F0F0F0',
    gray6: '#F5F5F5',
    gray6f: '#F0F0F0',
    gray7: '#FAFAFA',
    white: '#FFFFFF',
    primaryFocus: '#865dff',
    primary1: '#9675f8',
    primary2: '#af97f8',
    primary3: '#cfbdff',
    primary4: '#ebe4ff',
    primary5: '#f6f3ff',
    info: '#3ba0ff',
    danger: '#ff4d4f',
    warning: '#faad14',
    success: '#52c41a',
    staff1: '#5cc5be',
    staff2: '#ed5683',
    staff3: '#2e89de',
    staff4: '#ff9429',
    staff5: '#8a4b9b',
    staff6: '#f66b7b',
    staff7: '#149959',
    staff8: '#7986cb',
    staff9: '#2a4c7b',
    tag1: '#ff7ba2',
    tag2: '#ff9551',
    tag3: '#52c25b',
    tag4: '#639bff',
    tag5: '#63beff',
    tag6: '#ffbf00',
    tag7: '#8fa0ff',
    segment1: '#ffafaf',
    segment2: '#99daf2',
    segment3: '#89dfc3',
    segment4: '#fcc590',
    segment5: '#f7b3eb',
    segment6: '#bbc7f9',
    segment7: '#d8b3ff',
    segment8: '#bbe275',
    // 대기
    schedule1: '#fdedb2',
    // 확정
    schedule2: '#e2f2cf',
    // 매출
    schedule3: '#d9dae3',
    // 노쇼
    schedule4: '#ffd6e0',
    // 취소
    schedule5: '#FFD9C0'
};
