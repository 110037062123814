var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { COMMON_UI_COLORS, Layout, Typhography, CommonImages } from 'common-ui';
import styled, { css } from 'styled-components';
import ReactDom from 'react-dom';
export var TabButtons = function (props) {
    var _a;
    return (React.createElement(Layout.Inline, { gutter: 8 }, (_a = props.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return (React.createElement(LightBackgroundButton, __assign({ active: props.activeTab === item.key }, item, { onClick: function () { return props.onClick(item.key); } }))); })));
};
export var ContainButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  cursor: ", ";\n  height: 30px;\n  padding: 6px 12px 6px 12px;\n  border-radius: 2px;\n  background-color: ", ";\n  border: 0px;\n  height: ", ";\n    ", "}\n  color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n  ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  cursor: ", ";\n  height: 30px;\n  padding: 6px 12px 6px 12px;\n  border-radius: 2px;\n  background-color: ", ";\n  border: 0px;\n  height: ", ";\n    ", "}\n  color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n  ", ";\n"])), function (props) { return (props.disabled ? 'not-allowed' : 'pointer'); }, COMMON_UI_COLORS.primary1, function (props) { return (props.size === 'big' ? "36px" : "30px"); }, function (props) { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      ", ";\n    "], ["\n      ", ";\n    "])), Typhography.typographyByName(props.size === 'big' ? 'subtitle2' : 'subtitle3')); }, COMMON_UI_COLORS.white, COMMON_UI_COLORS.primary3, function (props) { return (props.fluid ? "width: 100%" : "width: auto"); });
export var LightBackgroundButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap;\n  cursor: pointer;\n  height: 40px;\n  border-radius: 6px;\n  width: 82px;\n  background-color: ", ";\n  color: ", ";\n  border: 0px;\n  ", "}\n\n  ", "\n\n  &:hover {\n    color:  ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: nowrap;\n  cursor: pointer;\n  height: 40px;\n  border-radius: 6px;\n  width: 82px;\n  background-color: ", ";\n  color: ", ";\n  border: 0px;\n  ", "}\n\n  ", "\n\n  &:hover {\n    color:  ", ";\n  }\n"])), COMMON_UI_COLORS.primary5, COMMON_UI_COLORS.primary1, css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    ", ";\n  "], ["\n    ", ";\n  "])), Typhography.typographyByName('headline2')), function (props) {
    return !props.active &&
        "\n  background-color: ".concat(COMMON_UI_COLORS.white, ";\n  color:  ").concat(COMMON_UI_COLORS.gray4, ";\n  ");
}, COMMON_UI_COLORS.primary1);
export var TextButtonWithIcon = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n", "\n", "}\n& > svg {\n  margin-right: 10px;\n  ", "\n}\ndisplay:flex;\nalign-items: center;\njustify-content: start;\nbackground-color: ", ";\nborder: 0px;\ncursor: pointer;\n:hover {\n  color: ", ";\n  & > svg {\n    margin-right: 10px;\n    color: ", ";\n  }\n}\n"], ["\n", "\n", "}\n& > svg {\n  margin-right: 10px;\n  ", "\n}\ndisplay:flex;\nalign-items: center;\njustify-content: start;\nbackground-color: ", ";\nborder: 0px;\ncursor: pointer;\n:hover {\n  color: ", ";\n  & > svg {\n    margin-right: 10px;\n    color: ", ";\n  }\n}\n"])), function (props) { return "color: ".concat(props.color || COMMON_UI_COLORS.primary1, ";"); }, css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), Typhography.typographyByName('headline3')), function (props) { return "color: ".concat(props.color || COMMON_UI_COLORS.primary1, ";"); }, COMMON_UI_COLORS.white, COMMON_UI_COLORS.primary3, COMMON_UI_COLORS.primary3);
export var FloatingButtonGroup = function (props) {
    var visible = props.visible, _a = props.confirmButtonProps, confirmButtonProps = _a === void 0 ? {} : _a, _b = props.cancelButtonProps, cancelButtonProps = _b === void 0 ? {} : _b;
    var modalPlaceholderElement = document.getElementById('root');
    return ReactDom.createPortal(React.createElement(FloatingButtonGroupWrapper, { "$visible": visible },
        React.createElement(LightBackgroundButton, __assign({ children: "\uCD08\uAE30\uD654" }, cancelButtonProps)),
        React.createElement(ContainButton, __assign({ children: "\uC800\uC7A5", type: "primary" }, confirmButtonProps))), modalPlaceholderElement);
};
var FloatingButtonGroupWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: fixed;\n  width: 100%;\n  bottom: 0px;\n  left: 0px;\n  background-color: white;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  z-index: 999999;\n  height: ", ";\n  opacity: ", ";\n\n  transition: height 0.5s;\n\n  & > button {\n    width: 100px;\n    &:first-child {\n      margin-right: 10px;\n    }\n  }\n  padding-left: 20px;\n  padding-right: 20px;\n  box-shadow: 0 0 5px 0 var(--primary-shadow);\n"], ["\n  position: fixed;\n  width: 100%;\n  bottom: 0px;\n  left: 0px;\n  background-color: white;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  z-index: 999999;\n  height: ", ";\n  opacity: ", ";\n\n  transition: height 0.5s;\n\n  & > button {\n    width: 100px;\n    &:first-child {\n      margin-right: 10px;\n    }\n  }\n  padding-left: 20px;\n  padding-right: 20px;\n  box-shadow: 0 0 5px 0 var(--primary-shadow);\n"])), function (props) { return (props.$visible ? '60px' : '0px'); }, function (props) { return (props.$visible ? 1 : 0); });
export var PremiumButton = function (props) {
    return (React.createElement(StyledForPremiumButton, { style: props.style, onClick: props.onClick },
        React.createElement(CommonImages.PremiumIcon, null),
        props.children));
};
var StyledForPremiumButton = styled(ContainButton)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  & > svg {\n    margin-right: 10px;\n  }\n  ", "}\n"], ["\n  background-color: ", ";\n  color: ", ";\n  & > svg {\n    margin-right: 10px;\n  }\n  ", "}\n"])), COMMON_UI_COLORS.primary5, COMMON_UI_COLORS.primary1, function (props) { return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    ", ";\n  "], ["\n    ", ";\n  "])), Typhography.typographyByName('subtitle3')); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
