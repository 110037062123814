var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, COMMON_UI_COLORS, Layout, Modal, Typhography } from 'common-ui';
import React from 'react';
import { get } from 'react-hook-form';
import styled, { css } from 'styled-components';
import Switch from 'react-switch';
import { FileUtils } from 'common-utils';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
var HorizonFormItem = function (props) {
    var formState = props.methods.formState;
    var error = get(formState.errors, props.name);
    return (React.createElement(Layout.Stack, null,
        React.createElement(Typhography.TextComponent, { children: props.label, type: "caption1", marginBottom: 4 }),
        props.children,
        (error === null || error === void 0 ? void 0 : error.message) && (React.createElement(Typhography.TextComponent, { color: COMMON_UI_COLORS.calendarRedActive }, (error === null || error === void 0 ? void 0 : error.message) || ''))));
};
export var TextField = function (props) {
    var name = props.name, methods = props.methods, label = props.label, prefix = props.prefix, placeholder = props.placeholder, _a = props.layout, layout = _a === void 0 ? 'horizon' : _a, _b = props.options, options = _b === void 0 ? {} : _b;
    var register = methods.register;
    var inputProps = register(name, options);
    var input = React.createElement(props.inputComponent || StyledTextField, __assign(__assign({}, inputProps), { placeholder: placeholder }));
    if (layout === 'horizon') {
        return React.createElement(HorizonFormItem, __assign({}, props, { children: input }));
    }
    else if (props.layout === 'none') {
        return input;
    }
    else {
        return input;
    }
};
export var TextareaField = function (props) {
    var name = props.name, _a = props.options, options = _a === void 0 ? {} : _a, methods = props.methods, label = props.label, prefix = props.prefix, placeholder = props.placeholder, _b = props.inputComponent, inputComponent = _b === void 0 ? StyledTextareaField : _b, _c = props.layout, layout = _c === void 0 ? 'horizon' : _c;
    var register = methods.register;
    var inputProps = register(name, options);
    var input = React.createElement(inputComponent, __assign(__assign({}, inputProps), { placeholder: placeholder, onChange: function (e) {
            if (props.prefix) {
                var input_1 = e.target.value;
                e.target.value = props.prefix + input_1.substr(props.prefix.length);
            }
            inputProps.onChange(e);
        } }));
    if (layout === 'horizon') {
        return React.createElement(HorizonFormItem, __assign({}, props, { children: input }));
    }
    else if (props.layout === 'none') {
        return input;
    }
    else {
        return input;
    }
};
export var CustomSwitch = function (props) {
    var _a = props.options, options = _a === void 0 ? {} : _a;
    var _b = props.methods, register = _b.register, watch = _b.watch;
    var checked = watch(props.name);
    var inputProps = register(props.name, options);
    return (React.createElement(Switch, __assign({}, inputProps, { onChange: function (checked, ev) {
            props.methods.setValue(props.name, checked, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
        }, onColor: COMMON_UI_COLORS.primary1, checked: checked, uncheckedIcon: null, checkedIcon: null })));
};
export var PictureUploaderWithCrop = function (props) {
    var _a = props.methods, register = _a.register, watch = _a.watch;
    var image = watch(props.name);
    var isValidImage = image && typeof image === 'string';
    var inputProps = register(props.name);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: props.containerStyle, className: props.className },
            isValidImage ? React.createElement("img", { src: image, style: props.previewStyle }) : props.placeholder || React.createElement("div", null),
            isValidImage ? (props.removeButton &&
                React.cloneElement(props.removeButton, {
                    onClick: function () {
                        return props.methods.setValue(props.name, null, {
                            shouldDirty: true
                        });
                    }
                })) : (React.createElement("label", { htmlFor: props.name }, props.inputButton || React.createElement("div", { children: "\uC774\uBBF8\uC9C0\uC5C5\uB85C\uB4DC" }))),
            React.createElement(CropperModal, { inputProps: inputProps, name: props.name, onChange: function (pictureString) {
                    props.methods.setValue(props.name, pictureString, {
                        shouldDirty: true
                    });
                } }))));
};
export var PictureSelectorWithCrop = function (props) {
    var onChangeImage = props.onChangeImage, inputButton = props.inputButton;
    var handleChange = function (pitcture) {
        if (typeof onChangeImage === 'function') {
            onChangeImage(pitcture);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("label", { htmlFor: props.name }, props.inputButton || React.createElement("div", { children: "\uC774\uBBF8\uC9C0\uC5C5\uB85C!!\uB4DC" })),
        props && React.createElement(CropperModal, { name: props.name, inputProps: { name: props.name }, onChange: handleChange })));
};
var CropperModal = function (props) {
    var _a = Modal.useModal(), add = _a.add, remove = _a.remove;
    var cropperRef = React.useRef(null);
    return (React.createElement("input", __assign({ type: "file", accept: 'image/jpg,image/jpeg,image/png', id: props.name, style: { display: 'none' } }, props.inputProps, { name: props.name, onChange: function (ev) {
            var _a, _b;
            if (!((_a = ev === null || ev === void 0 ? void 0 : ev.target) === null || _a === void 0 ? void 0 : _a.files)) {
                return;
            }
            var image = ev.target.files[0];
            ev.stopPropagation();
            ev.preventDefault();
            if (!!((_b = image === null || image === void 0 ? void 0 : image.type) === null || _b === void 0 ? void 0 : _b.match('image.*'))) {
                FileUtils.convertFileToBase64(image)
                    .then(function (base64) {
                    var modalId = add({
                        title: '사진편집',
                        contentStyles: {
                            width: '80%'
                        },
                        content: (React.createElement(Cropper, { src: base64, autoCropArea: 1, style: { height: 400, width: '100%' }, guides: true, ref: cropperRef })),
                        footer: (React.createElement(Layout.FluidInline, { gutter: 8, stretch: "all" },
                            React.createElement(Button.ContainButton, { onClick: function () {
                                    remove(modalId);
                                } }, "\uCDE8\uC18C"),
                            React.createElement(Button.ContainButton, { onClick: function () {
                                    var imageElement = cropperRef === null || cropperRef === void 0 ? void 0 : cropperRef.current;
                                    var cropper = imageElement === null || imageElement === void 0 ? void 0 : imageElement.cropper;
                                    props.onChange(cropper.getCroppedCanvas().toDataURL());
                                    remove(modalId);
                                } }, "\uC644\uB8CC")))
                    });
                })
                    .catch(function (err) {
                    console.log('err', err);
                });
            }
        } })));
};
var StyledTextField = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 28px;\n  padding: 0px 12px 0px 12px;\n  border-radius: 2px;\n  border: 0px;\n  border: 1px solid ", ";\n  ", "}\n  &::placeholder {\n    color: ", ";\n  }\n\n  &:focus-visible {\n    outline: 0px;\n  }\n  "], ["\n  height: 28px;\n  padding: 0px 12px 0px 12px;\n  border-radius: 2px;\n  border: 0px;\n  border: 1px solid ", ";\n  ", "}\n  &::placeholder {\n    color: ", ";\n  }\n\n  &:focus-visible {\n    outline: 0px;\n  }\n  "])), COMMON_UI_COLORS.gray6, css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", ";\n  "], ["\n    ", ";\n  "])), Typhography.typographyByName('caption1')), COMMON_UI_COLORS.gray4);
export var GrayTextField = styled(StyledTextField)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  background-color: ", ";\n"], ["\n  border: 1px solid ", ";\n  background-color: ", ";\n"])), COMMON_UI_COLORS.gray7, COMMON_UI_COLORS.gray7);
var StyledTextareaField = styled.textarea(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 0px 12px 0px 12px;\n  border-radius: 2px;\n  border: 0px;\n  height: 100px;\n  border: 1px solid ", ";\n  ", "}\n  &::placeholder {\n    color: ", ";\n  }\n  &:focus-visible {\n    outline: 0px;\n  }\n  "], ["\n  padding: 0px 12px 0px 12px;\n  border-radius: 2px;\n  border: 0px;\n  height: 100px;\n  border: 1px solid ", ";\n  ", "}\n  &::placeholder {\n    color: ", ";\n  }\n  &:focus-visible {\n    outline: 0px;\n  }\n  "])), COMMON_UI_COLORS.gray6, css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    ", ";\n  "], ["\n    ", ";\n  "])), Typhography.typographyByName('caption1')), COMMON_UI_COLORS.gray4);
// interface RadioWithIcon<T> extends any {
//   options: RadioOption[];
//   className?: string;
//   columns?: number;
// }
export var RadioButtonWithLabelElement = function (props) {
    var _a = props.methods, register = _a.register, watch = _a.watch;
    var value = watch(props.name);
    var inputProps = register(props.name);
    var name = inputProps.name, rest = __rest(inputProps, ["name"]);
    var Radio = function (option) {
        var checked = value === option.value;
        var id = "".concat(option.value, "_").concat(props.name);
        return (React.createElement("div", { key: option.value, className: props.className },
            React.createElement("input", __assign({ type: "radio", id: id, style: { display: 'none' }, name: name, value: option.value, checked: option.value === value }, rest)),
            React.createElement("label", { htmlFor: id }, option.checkedLabel ? (checked ? option.checkedLabel : option.label) : option.label)));
    };
    return (React.createElement(Layout.Inline, null,
        React.createElement(Layout.FluidColumns, { columns: props.columns || 2 }, props.options.map(Radio))));
};
export var RadioButtonWithIcon = function (props) {
    var _a = props.methods, register = _a.register, watch = _a.watch;
    var value = watch(props.name);
    var inputProps = register(props.name);
    var name = inputProps.name, rest = __rest(inputProps, ["name"]);
    var Radio = function (option) {
        var checked = value === option.value;
        var id = "".concat(option.value, "_").concat(props.name);
        return (React.createElement(RadioWrapper, { gutter: 8, key: option.value, className: props.className, justify: "center" },
            React.createElement("input", __assign({ type: "radio", id: id, name: name, value: option.value, checked: option.value === value }, rest)),
            React.createElement("label", { htmlFor: id }, option.checkedLabel ? (checked ? option.checkedLabel : option.label) : option.label)));
    };
    return React.createElement(Layout.FluidInline, { gutter: 8 }, props.options.map(Radio));
};
// works
var RadioWrapper = styled(function (props) { return React.createElement(Layout.FluidInline, __assign({}, props)); })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  & > label {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 30px;\n    width: 100%;\n    border-radius: 2px;\n    background-color: ", ";\n    cursor: pointer;\n    :hover {\n      background-color: ", ";\n    }\n  }\n  & > input {\n    display: none;\n    &:checked + label {\n      background-color: ", ";\n      & > svg {\n        color: ", ";\n      }\n    }\n  }\n"], ["\n  & > label {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 30px;\n    width: 100%;\n    border-radius: 2px;\n    background-color: ", ";\n    cursor: pointer;\n    :hover {\n      background-color: ", ";\n    }\n  }\n  & > input {\n    display: none;\n    &:checked + label {\n      background-color: ", ";\n      & > svg {\n        color: ", ";\n      }\n    }\n  }\n"])), COMMON_UI_COLORS.gray7, COMMON_UI_COLORS.gray7, COMMON_UI_COLORS.gray1, COMMON_UI_COLORS.white);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
