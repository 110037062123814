var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DndContext, useSensors, useSensor, MouseSensor, TouchSensor } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon, Inputs, Layout, Card, Typhography, COMMON_UI_COLORS, Modal, Button } from 'common-ui';
import React from 'react';
import styled from 'styled-components';
import { SVGIcons } from './images';
import { BlackIcon } from './Page';
export var SortableWrapper = function (props) {
    var sensors = useSensors(useSensor(MouseSensor, {
        activationConstraint: {
            distance: 10
        }
    }), useSensor(TouchSensor, {
        activationConstraint: {
            delay: 100,
            tolerance: 5
        }
    }));
    return (React.createElement(DndContext, { sensors: sensors, onDragEnd: props.onDragEnd },
        React.createElement(SortableContext, { items: props.items.map(function (item, index) { return index; }) }, props.child)));
};
export var SortableBlocks = function (props) {
    return (React.createElement(SortableWrapper, { items: props.items, child: props.items.map(function (item, index) {
            return (React.createElement(SortableCollapsibleCardItem, __assign({ onDelete: function (id) {
                    var _a;
                    props.fieldsFunc.remove((_a = props.fieldsFunc.fields) === null || _a === void 0 ? void 0 : _a.findIndex(function (item) { return item.id === id; }));
                }, key: "".concat(item.id), index: index, fieldsFunc: props.fieldsFunc, methods: props.methods }, item)));
        }), onDragEnd: function (_a) {
            var active = _a.active, over = _a.over;
            if (!over) {
                return;
            }
            if (active.id !== over.id) {
                props.fieldsFunc.move(active.id, over.id);
            }
        } }));
};
var SortableCollapsibleCardItem = function (props) {
    var methods = props.methods;
    var handleChange = function (isOpen) {
        if (isOpen) {
            methods.setValue('openedCardId', props.id);
        }
        else {
            methods.setValue('openedCardId', '');
        }
    };
    var openedCardId = methods === null || methods === void 0 ? void 0 : methods.watch('openedCardId');
    var isOpen = openedCardId === props.id;
    var _a = Modal.useModal(), add = _a.add, remove = _a.remove;
    var handleDelete = function () {
        var modalId = add({
            title: '정말 삭제하실래요?',
            content: '한번 삭제된 메뉴는 복구하실 수 없습니다.',
            footer: (React.createElement(Layout.FluidInline, { gutter: 8, stretch: "all" },
                React.createElement(Button.ContainButton, { onClick: function () {
                        remove(modalId);
                    } }, "\uCDE8\uC18C"),
                React.createElement(Button.ContainButton, { onClick: function () {
                        if (props.onDelete) {
                            props.onDelete(props.id);
                        }
                        remove(modalId);
                    } }, "\uD655\uC778")))
        });
    };
    if (props.isSortable) {
        var blockName = "blocks.".concat(props.index);
        var switchName = "".concat(blockName, ".active");
        var _b = useSortable({ id: props.index }), attributes = _b.attributes, listeners = _b.listeners, setNodeRef = _b.setNodeRef, transform = _b.transform, transition = _b.transition;
        var style = {
            transform: CSS.Transform.toString(transform),
            transition: transition
        };
        return (React.createElement("div", __assign({ ref: setNodeRef, style: style }, attributes),
            React.createElement(Card.CollapsibleCard, { isOpen: isOpen, onChange: handleChange, header: React.createElement(Layout.Inline, { align: "center", gutter: 8, stretch: 2, style: { paddingLeft: '10px' } },
                    isOpen ? (React.createElement(Inputs.CustomSwitch, { methods: methods, name: switchName })) : (React.createElement(SVGIcons.DragNormal, __assign({ style: { cursor: 'grab' } }, listeners, { width: 20, color: COMMON_UI_COLORS.white }))),
                    React.createElement(BlackIcon, { name: props.iconName }),
                    React.createElement(Typhography.TextComponent, { children: "".concat(props.title) }),
                    isOpen ? (React.createElement(ClickableInline, { gutter: 4, align: "center", onClick: handleDelete },
                        React.createElement(Typhography.TextComponent, { children: "\uC0AD\uC81C", color: COMMON_UI_COLORS.calendarRedActive, type: "caption1" }),
                        React.createElement(Icon.Icon, { name: "faTrash", color: COMMON_UI_COLORS.calendarRedActive, size: 16 }))) : (React.createElement("div", null))), content: props.content })));
    }
    else {
        return (React.createElement(Card.CollapsibleCard, { header: React.createElement(Layout.Inline, { align: "center", gutter: 8, stretch: 1, style: { paddingLeft: '10px' } },
                React.createElement(BlackIcon, { name: props.iconName }),
                React.createElement(Typhography.TextComponent, { children: "".concat(props.title) })), content: props.content, isOpen: isOpen, onChange: handleChange }));
    }
};
var ClickableInline = styled(Layout.Inline)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var StyledImage = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: grab;\n  margin-right: 10px;\n"], ["\n  cursor: grab;\n  margin-right: 10px;\n"])));
var templateObject_1, templateObject_2;
