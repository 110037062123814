export var PageModel;
(function (PageModel) {
    var COLORS;
    (function (COLORS) {
        COLORS["RED"] = "#fa4846";
        COLORS["ORANGE"] = "#fe8a03";
        COLORS["YELLOW"] = "#ffc42c";
        COLORS["GREEN"] = "#03c042";
        COLORS["SKY_BLUE"] = "#00cffe";
        COLORS["BLUE"] = "#3e5afc";
        COLORS["PURPLE"] = "#a653fb";
        COLORS["BLACK"] = "#171d2e";
    })(COLORS = PageModel.COLORS || (PageModel.COLORS = {}));
    var TYPES;
    (function (TYPES) {
        TYPES["PROFILE"] = "profile";
        TYPES["TEXT"] = "text";
        TYPES["LINK"] = "link";
        TYPES["IMAGE"] = "image";
        TYPES["SNS"] = "sns";
    })(TYPES = PageModel.TYPES || (PageModel.TYPES = {}));
})(PageModel || (PageModel = {}));
