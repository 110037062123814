export var convertFileToBase64 = function (file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise(function (resolve, reject) {
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            reject(reader.result);
            console.log('Error: ', error);
        };
    });
};
