var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { COMMON_UI_COLORS, OverlayScroll } from 'common-ui';
import styled from 'styled-components';
export var PhoneContainer = function (props) {
    return (React.createElement(Wrapper, { theme: props.theme, style: props.style },
        React.createElement(Inner, null,
            React.createElement(Scroll, { options: {
                    scrollbars: {
                        autoHide: 'leave',
                        autoHideDelay: 100
                    },
                    overflowBehavior: {
                        y: 'scroll'
                    }
                } }, props.children))));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 380px;\n  max-height: 760px;\n  height: 80vh;\n  padding: 5px;\n  border-radius: 23px;\n  box-shadow: 0 3px 20px 1px ", ";\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 380px;\n  max-height: 760px;\n  height: 80vh;\n  padding: 5px;\n  border-radius: 23px;\n  box-shadow: 0 3px 20px 1px ", ";\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), COMMON_UI_COLORS.gray7, function (props) { return (props.theme === 'light' ? COMMON_UI_COLORS.gray7 : COMMON_UI_COLORS.gray2); });
var Inner = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 360px;\n  mex-height: 760px;\n  height: calc(100% - 10px);\n  border-radius: 23px;\n"], ["\n  width: 360px;\n  mex-height: 760px;\n  height: calc(100% - 10px);\n  border-radius: 23px;\n"])));
var Scroll = styled(OverlayScroll)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  width: 100%;\n  height: 100%;\n  border-radius: 10px;\n"], ["\n  background-color: ", ";\n  width: 100%;\n  height: 100%;\n  border-radius: 10px;\n"])), COMMON_UI_COLORS.white);
var templateObject_1, templateObject_2, templateObject_3;
