var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Inline } from '@bedrock-layout/inline';
import { Stack } from '@bedrock-layout/stack';
import { Grid } from '@bedrock-layout/grid';
import { Columns, Column } from '@bedrock-layout/columns';
import styled from 'styled-components';
import React from 'react';
import { Icon } from './Icon';
import { COMMON_UI_COLORS, ReactHooks } from 'common-ui';
export var FluidInline = styled(Inline)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var FluidStack = styled(Stack)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var FluidColumns = styled(Columns)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var PadBox = function (props) {
    var paddingString = props.paddingString, all = props.all, left = props.left, right = props.right, top = props.top, bottom = props.bottom, horizon = props.horizon, vertical = props.vertical, className = props.className, children = props.children;
    return (React.createElement("div", { className: className, style: {
            padding: paddingString
                ? paddingString
                : "".concat(top || vertical || all || 0, "px ").concat(right || horizon || all || 0, "px ").concat(bottom || vertical || all || 0, "px ").concat(left || horizon || all || 0, "px")
        }, children: children }));
};
var AppLayout = function (props) {
    var _a;
    var _b = React.useState(props.sidebarOpen), sidebarOpen = _b[0], setSidebarOpen = _b[1];
    var _c = ReactHooks.useHover(), ref = _c[0], isHover = _c[1];
    var debouncedIsOpen = ReactHooks.useDebounce(sidebarOpen, 100);
    React.useEffect(function () {
        if (props.sidebarOpen) {
            return setSidebarOpen(true);
        }
        else {
            if (isHover) {
                return setSidebarOpen(true);
            }
            else {
                return setSidebarOpen(false);
            }
        }
    }, [isHover, props.sidebarOpen]);
    return (React.createElement(Root, null,
        React.createElement(Header, null,
            React.createElement(FluidInline, { stretch: 1 },
                React.createElement(DrawerIcon, { name: "menu", size: 32, onClick: props.onSideOpenChange }),
                props.header.children)),
        React.createElement(Body, null,
            ((_a = props.sidebar) === null || _a === void 0 ? void 0 : _a.children) && props.isPC && (React.createElement(Sidebar, { ref: ref, "$sidebarOpen": debouncedIsOpen }, props.sidebar.children)),
            props.isPC ? (React.createElement(Main, { "$sidebarOpen": debouncedIsOpen }, props.main.children)) : (React.createElement(MobileMain, null, props.main.children)))));
};
var HEADER_HEIGHT = 60;
var Root = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 100vh;\n  width: 100vw;\n  background-color: ", ";\n"], ["\n  height: 100vh;\n  width: 100vw;\n  background-color: ", ";\n"])), COMMON_UI_COLORS.white);
var DrawerIcon = styled(Icon)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 2px;\n  margin-right: 10px;\n  cursor: pointer;\n"], ["\n  margin-top: 2px;\n  margin-right: 10px;\n  cursor: pointer;\n"])));
var Header = styled.header(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0px 20px;\n  height: ", "px;\n  display: flex;\n  align-items: center;\n  border-bottom: solid 1px ", ";\n"], ["\n  width: 100%;\n  padding: 0px 20px;\n  height: ", "px;\n  display: flex;\n  align-items: center;\n  border-bottom: solid 1px ", ";\n"])), HEADER_HEIGHT, COMMON_UI_COLORS.gray5);
var Body = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: columns;\n  height: calc(100vh - ", "px);\n  width: 100vw;\n"], ["\n  display: flex;\n  flex-direction: columns;\n  height: calc(100vh - ", "px);\n  width: 100vw;\n"])), HEADER_HEIGHT);
var Sidebar = styled.aside(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  transition: width 0.6s ease;\n  width: ", ";\n  height: 100%;\n"], ["\n  transition: width 0.6s ease;\n  width: ", ";\n  height: 100%;\n"])), function (props) { return (props.$sidebarOpen ? '220px' : '64px'); });
var Main = styled.main(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  border-left: 1px solid ", ";\n  transition: width 0.6s ease;\n  width: calc(100vw - ", ");\n  & > * {\n    height: 100%;\n  }\n  z-index: 1;\n"], ["\n  width: 100%;\n  height: 100%;\n  border-left: 1px solid ", ";\n  transition: width 0.6s ease;\n  width: calc(100vw - ", ");\n  & > * {\n    height: 100%;\n  }\n  z-index: 1;\n"])), COMMON_UI_COLORS.gray5, function (props) { return (props.$sidebarOpen ? '220px' : '64px'); });
var MobileMain = styled(Main)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100vw;\n"], ["\n  width: 100vw;\n"])));
export { Inline, Stack, Columns, Column, AppLayout, Grid };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
