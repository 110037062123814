var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { COMMON_UI_COLORS, Icon, Layout, Typhography } from 'common-ui';
import { useTrail, animated } from 'react-spring';
import omit from 'lodash/omit';
import styled from 'styled-components';
export var CollapsibleCard = function (props) {
    var _a = React.useState(props.isOpen || false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useTrail(1, function () { return ({
        opacity: 0,
        minHeight: '0px',
        maxHeight: '1000px'
    }); }), trail = _b[0], api = _b[1];
    React.useEffect(function () {
        if (props.isOpen !== isOpen) {
            api.start(omit({
                opacity: !props.isOpen ? 0 : 1,
                minHeight: !props.isOpen ? '0px' : '0px',
                maxHeight: !props.isOpen ? '0px' : '1000px'
            }));
            setIsOpen(Boolean(props.isOpen || false));
        }
    }, [props.isOpen]);
    var transitionComp = trail.map(function (styles, index) {
        return (React.createElement(animated.div, { key: index, style: __assign(__assign({}, styles), { display: styles.opacity.to(function (op) {
                    return op === 0 ? 'none' : 'block';
                }) }), children: React.createElement(CardContent, null, props.content) }));
    });
    return (React.createElement(ShadowBox, null,
        React.createElement(Layout.FluidStack, null,
            React.createElement(Layout.FluidInline, { align: "center", gutter: 8, stretch: 0, style: { marginBottom: '5px', marginTop: '5px' }, onClick: function () {
                    if (props.disableOpen) {
                        return;
                    }
                    api.start(omit({
                        opacity: isOpen ? 0 : 1,
                        minHeight: isOpen ? '0px' : '0px',
                        maxHeight: isOpen ? '0px' : '1000px'
                    }));
                    setIsOpen(!isOpen);
                    if (props.onChange) {
                        props.onChange(!isOpen);
                    }
                } },
                props.header,
                !props.disableOpen ? React.createElement(CollapseButton, { name: isOpen ? 'up-chevron' : 'down-chevron' }) : React.createElement("div", null)),
            transitionComp)));
};
export var Card = function (props) {
    return (React.createElement(ShadowBox, null,
        React.createElement(Layout.FluidStack, null,
            React.createElement(Layout.FluidInline, { align: "center", justify: "center", gutter: 8, stretch: 0, style: { marginBottom: '10px', marginLeft: '10px' } }, typeof props.header === 'string' ? React.createElement(Typhography.TextComponent, { children: props.header }) : props.header),
            React.createElement(CardContent, { gutter: 8, justify: "center", align: "center" }, props.content))));
};
var CardContent = styled(function (props) { return React.createElement(Layout.Inline, __assign({}, props)); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-top: solid 1px ", ";\n  padding: 10px;\n"], ["\n  border-top: solid 1px ", ";\n  padding: 10px;\n"])), COMMON_UI_COLORS.gray7);
var CollapseButton = styled(function (props) { return React.createElement(Icon.ClickableIcon, __assign({}, props)); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: 5px;\n  font-size: 24px;\n"], ["\n  margin-right: 5px;\n  font-size: 24px;\n"])));
export var ShadowBox = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  padding: 9px 0px;\n  border-radius: 4px;\n  box-shadow: 0 3px 20px 1px ", ";\n  border: solid 1px ", ";\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n"], ["\n  width: 100%;\n  padding: 9px 0px;\n  border-radius: 4px;\n  box-shadow: 0 3px 20px 1px ", ";\n  border: solid 1px ", ";\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n"])), COMMON_UI_COLORS.gray7, COMMON_UI_COLORS.gray7, COMMON_UI_COLORS.white);
var templateObject_1, templateObject_2, templateObject_3;
